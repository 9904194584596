<template>
  <div style="height: 100%;">
    <section class="container">
      <v-row class="image__title d-flex justify-center align-start pt-8">
        <img width="10%" src="../../assets/conteudo_aguas_icon.png" alt="" />
      </v-row>
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
      </v-row>
    </section>
    <section class="container d-flex flex-column pos-rel">
      <img
        class="bg_water_img"
        src="../../assets/conteudo_aguas_rio.png"
        alt=""
      />

      <v-row class="w-100" style="z-index: 15;">
        <v-col class="img__links d-flex justify-center align-center">
          <img
            @click="goToPage('conteudos_tematicos_aguas_rejeito')"
            src="../../assets/conteudo_aguas_title_02.png"
            alt=""
            class="mr-md-auto"
          />
        </v-col>
      </v-row>

      <v-row class="w-100" style="z-index: 15;">
        <v-col class="img__links d-flex justify-center align-center">
          <img
            @click="goToPage('conteudos_tematicos_aguas_lama')"
            src="../../assets/conteudo_aguas_title_01.png"
            alt=""
            class="ml-md-auto"
          />
        </v-col>
      </v-row>

      <v-row class="w-100" style="z-index: 15;">
        <v-col class="img__links d-flex justify-center align-center">
          <img
            @click="goToPage('')"
            src="../../assets/conteudo_aguas_title_04.png"
            alt=""
            class="mr-md-auto"
          />
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Breadcrumb from "@/layout/Breadcrumb";
export default {
  components: { Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Águas /",
          href: "conteudos_tematicos_aguas",
        },
        {
          text: "Início",
          href: "conteudos_tematicos_aguas",
        },
      ],
    };
  },
  methods: {
    goToPage(path) {
      this.$router.push({ name: path });
    },
  },
};
</script>

<style scoped>
.pos-rel {
  position: relative;
  display: grid;
  place-items: center;
}

.w-100 {
  width: 100%;
}

.bg_water_img {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 60%;
}
.img__links img {
  width: 20%;
  cursor: pointer;
}

@media (max-width: 600px) {
  .img__links img {
    width: 70%;
  }

  .bg_water_img {
    width: 100%;
  }

  .image__title img {
    width: 30%;
  }
}
</style>
